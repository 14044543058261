import { useState } from 'react';

import { CopyIcon } from './icons/CopyIcon';
import { Dialog, DialogContent, DialogTrigger } from './Modal';

export const ReferralDialogTriggerWrapper = ({
  memberId,
  children,
}: {
  memberId: string;
  children: React.ReactNode;
}) => {
  const referralLink =
    'https://app.sheerhealth.com/registration?src=member_ref&referrerUID=' +
    memberId;
  const [copied, setCopied] = useState(false);

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 5000);
  };

  const smsLink = `sms:?body=Sheer%20Health%20has%20saved%20me%20time%2C%20money%2C%20and%20headaches%20by%20dealing%20with%20my%20health%20insurance%20and%20medical%20bills.%0D%0A%0D%0ASign%20up%20here%3A%20https%3A%2F%2Fapp.sheerhealth.com%2Fregistration%3Fsrc%3Dmember_ref%26referrerUID%3D${memberId}`;

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <div
        id='rewardId'
        className='z-[100] absolute w-[5px] h-[5px] m-auto left-0 right-0 top-0 bottom-0'
      ></div>
      <DialogContent
        className='w-5/6 flex flex-col items-center bg-white lg:w-fit rounded-[8px]'
        closeButton={false}
      >
        <div className='flex flex-col items-center w-full gap-4'>
          <div className='flex flex-col items-start w-full'>
            <p className='text-[24px] mb-2 text-[#211F26]'>
              Enjoying Sheer? Refer a friend 💜
            </p>
            <p className='text-[16px] text-[#65636D]'>
              Invite your friends and family to join Sheer Health by sharing the
              referral link below.
            </p>
          </div>
          <div className='flex items-start lg:items-center justify-between gap-4 p-4 bg-slate-200 rounded-[8px] w-full overflow-auto'>
            <p className='text-[16px] overflow-auto text-nowrap lg:text-[14px]'>
              {referralLink}
            </p>
          </div>
          <div className='flex flex-col items-center justify-center w-full gap-4 pt-2 lg:flex-row'>
            <a
              href={smsLink}
              className='flex items-center justify-center bg-indigo-100 rounded-[8px] text-[16px] lg:text-[14px] py-2 px-4 text-nowrap w-full lg:w-fit outline-none'
            >
              Share via SMS
            </a>
            <button
              className='flex items-center justify-center gap-1 bg-purple-100 rounded-[8px] text-[16px] lg:text-[14px] py-2 px-4 text-nowrap w-full lg:w-fit outline-none'
              onClick={copyLinkToClipboard}
            >
              {copied ? (
                'Copied ✓'
              ) : (
                <>
                  Copy
                  <CopyIcon />
                </>
              )}
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
