import { useReward } from 'react-rewards';

import { ReferralDialogTriggerWrapper } from './ReferralDialogTriggerWrapper';

import { formatNumberToRoundedDollars } from '@/utils/currencyFormatter';

const animationConfig = {
  lifetime: 250,
  spread: 300,
  decay: 0.93,
  angle: 90,
  startVelocity: 35,
  elementCount: 100,
  elementSize: 10,
  colors: ['#531cb3', '#944bbb', '#D08AF7', '#EB83D8', '#EFB1F0'],
};

export const AmountSavedButton = ({
  amountSaved,
  memberId,
}: {
  amountSaved: number;
  memberId: string;
}) => {
  const { reward, isAnimating } = useReward(
    'rewardId',
    'confetti',
    animationConfig,
  );

  return (
    <ReferralDialogTriggerWrapper memberId={memberId}>
      <button
        className='transition-all cursor-pointer w-full max-w-[528px] rounded-[8px] bg-gradient-to-r from-purple-100 via-indigo-100 to-purple-100 bg-[400%] animate-gradient-xy'
        disabled={isAnimating}
        onClick={reward}
      >
        <div className='bg-[linear-gradient(45deg,transparent_25%,rgba(255,255,255,.5)_50%,transparent_75%,transparent_100%)] bg-[length:250%_250%,100%_100%] bg-[position:-100%_0,0_0] bg-no-repeat overflow-hidden transition-[background-position_0s_ease] hover:bg-[position:200%_0,0_0] hover:duration-[800ms] hover:delay-100 text-[#3D155D]'>
          <div className='flex items-baseline justify-center py-1 my-2'>
            <p className='font-arizona-flare text-[32px] overflow-auto text-nowrap mx-4 scrollbar-hide'>
              {formatNumberToRoundedDollars(amountSaved)}
              <span className='text-[16px] pl-1 pb-2'>
                saved with Sheer Health
              </span>
            </p>
          </div>
        </div>
      </button>
    </ReferralDialogTriggerWrapper>
  );
};
